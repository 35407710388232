@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.sectionWWD {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 40px;
    padding-bottom: 40px;
    position: relative; /* Needed for positioning the pseudo-element */
    z-index: 1;
  }
  
  .sectionWWD::after {
    content: '';
    display: block;
    height: 2px;
    background-color: rgb(205, 205, 205);
    width: 80%; /* Adjust the width of the line as needed */
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%); /* Center the line horizontally */
  }
  
  .section-imageWWD {
    object-fit: cover;
    width: 40%; /* Adjust the width as needed */
    height: auto; /* Maintain aspect ratio */
    margin: 10px;
    z-index: 1;
  }
  
  .section-textWWD {
    width: 55%; /* Adjust the width as needed */
    padding: 0 15px;
    font-family: 'Montserrat', sans-serif;
    color: rgb(70, 70, 70);
  }
  
  .section-reverseWWD {
    flex-direction: row-reverse;
    z-index: 1;
  }

  @media screen and (max-width: 768px) {
    .sectionWWD {
      flex-direction: column; /* Stack items vertically */
      text-align: center; /* Center-align text for a better mobile layout */
      padding-left: 60px;
      padding-right: 60px;
      padding-top: 30px;
      padding-bottom: 30px;
      z-index: 1;
    }
  
    .section-imageWWD, .section-textWWD {
      width: 100%; /* Full width for mobile layout */
      margin: 10px 0; /* Adjust margin for vertical spacing */
      font-family: 'Montserrat', sans-serif;
      color: rgb(70, 70, 70);
      z-index: 1;
    }
  
    .section.reverseWWD {
      flex-direction: column; /* Keep the same direction for mobile */
      z-index: 1;
    }
  
    .sectionWWD::after {
      content: '';
      display: block;
      height: 2px;
      background-color: rgb(205, 205, 205);
      width: 50%; /* Adjust the width of the line for mobile */
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%); /* Center the line horizontally */
    }
  }