@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.founding-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly; /* This will handle spacing automatically */
    margin: 20px auto; /* Center the section with automatic margins */
    max-width: 80%;
  }
  
  .founding {
    flex: 1 1 calc(33.333% - 40px); /* Three images per row, accounting for margin */
    margin: 20px; /* Space around each chapter */
    display: flex;
    flex-direction: column;
    align-items: center; /* Center-align the content */
  }
  
  .founding-image {
    width: 100%; /* Make the image take full width of its container */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover;
  }
  
  .founding-info {
    text-align: center; /* Center the text below the image */
    font-family: 'Montserrat', sans-serif;
    color: rgb(70, 70, 70);
    padding: 10px;
  }
  .foundingheader {
    text-align: center; /* Center the heading */
    margin-top: 50px; /* Add space above the heading */
    margin-bottom: 30px; /* Add space below the heading */
    font-family: 'Montserrat', sans-serif;
    color: rgb(70, 70, 70);
  }
  .name-line {
    display: flex; /* This will enable flexbox */
    align-items: center; /* This will vertically center the items in the flex container */
    justify-content: center; /* This will horizontally center the items (if you want them centered) */
  }
  .alphaclass-image{
    width: 77%;
  }
  .alphaclassheader{
    text-align: center; /* Center the heading */
    margin-top: 50px; /* Add space above the heading */
    margin-bottom: 30px; /* Add space below the heading */
    font-family: 'Montserrat', sans-serif;
    color: rgb(70, 70, 70);
  }
  .alphaclassdiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
  }
  .alphaclassnames{
    font-family: 'Montserrat', sans-serif;
    color: rgb(70, 70, 70);
    text-align: center;
    font-size: larger;
  }
  @media screen and (max-width: 768px) {
    .founding-section {
        flex-direction: column;
        align-items: center;
        max-width: 100%;
        margin: 0; /* Adjust margin for mobile */
      }
    
      .founding {
        width: 60%; /* Full width for mobile layout */
        margin-bottom: 20px; /* Space below each chapter */
      }
    
      .founding-info h3, .founding-info p {
        margin: 5px 0; /* Adjust text margins for mobile */
        font-family: 'Montserrat', sans-serif;
        color: rgb(70, 70, 70);
      }
      .founding-image {
        height: 300px; /* Example fixed height, adjust as needed */
      }
      .alphaclassnames{
        padding-left: 20px;
        padding-right: 20px;
      }
  }