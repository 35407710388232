@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.history-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 20px;
    padding-left: 100px;
    padding-right: 100px;
  }
  
  .history-text, .origin-text {
    width: 48%; /* Adjust the width as needed */
    font-family: 'Montserrat', sans-serif;
    color: rgb(70, 70, 70);
  }
  
  .history-text h2, .origin-text h3 {
    margin-bottom: 20px;
    font-family: 'Montserrat', sans-serif;
    color: rgb(70, 70, 70);
  }
  
  .campus-image {
    width: 100%; /* Make the image responsive */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 20px; /* Space between the image and the following text */
  }
  .chapter-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly; /* This will handle spacing automatically */
    margin: 20px auto; /* Center the section with automatic margins */
    max-width: 80%;
  }
  
  .chapter {
    flex: 1 1 calc(33.333% - 40px); /* Three images per row, accounting for margin */
    margin: 20px; /* Space around each chapter */
    display: flex;
    flex-direction: column;
    align-items: center; /* Center-align the content */
  }
  
  .chapter-image {
    object-fit: cover;
    width: 100%;
    height: 200px;
  }
  
  .chapter-info {
    text-align: center; /* Center the text below the image */
    font-family: 'Montserrat', sans-serif;
    color: rgb(70, 70, 70);
    padding: 10px;
  }
  .omicron-section {
    text-align: center; /* Center-align the heading */
    width: 80%; /* Adjust the width as needed */
    margin: 0 auto; /* Center the container */
    padding-bottom: 20px;
  }
  
  .omicron-heading {
    margin-bottom: 20px;
    font-family: 'Montserrat', sans-serif;
    color: rgb(70, 70, 70);
  }
  
  .omicron-text {
    text-align: left;
    font-family: 'Montserrat', sans-serif;
    color: rgb(70, 70, 70);
  }
  .notablechapters {
    padding-left: 5px;
    padding-right: 5px;
    text-align: center; /* Center the heading */
    margin-top: 50px; /* Add space above the heading */
    margin-bottom: 30px; /* Add space below the heading */
    font-family: 'Montserrat', sans-serif;
    color: rgb(70, 70, 70);
  }
  
  @media screen and (max-width: 768px) {
    .history-section {
      flex-direction: column;
      align-items: center;
      padding-left: 50px;
      padding-right: 50px;
    }
  
    .history-text, .origin-text {
      width: 100%;
      font-family: 'Montserrat', sans-serif;
      color: rgb(70, 70, 70);
    }

    .chapter-section {
        flex-direction: column;
        align-items: center;
        max-width: 100%;
        margin: 0; /* Adjust margin for mobile */
      }
    
      .chapter {
        width: 60%; /* Full width for mobile layout */
        margin-bottom: 20px; /* Space below each chapter */
      }
    
      .chapter-info h3, .chapter-info p {
        margin: 5px 0; /* Adjust text margins for mobile */
        font-family: 'Montserrat', sans-serif;
        color: rgb(70, 70, 70);
      }
      .chapter-image {
        height: 200px; /* Example fixed height, adjust as needed */
      }
      .omicron-section {
        width: 80%;
      }
  }