@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@400;700&display=swap');

.header-wrapper {
  background-color: black;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-out;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-out;
}

.header {
  background-color: black;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 20px;
  height: 50vh; /* Set the height to 60% of the viewport height */
  background-size: cover; /* Ensure the background image covers the header */
  background-position: center;
  position: relative;
}

.header-title {
  position: absolute;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust for the element's dimensions */
  color: white; /* Example text color, adjust as needed */
  font-family: 'Cormorant Garamond', serif;
  font-size: 5em; /* Increased font size */
  font-weight: bold; /* Making the font bolder */
  text-align: center; /* Ensuring the text is centered */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); 
}

.header .logo img {
  width: 300px; /* Adjust as per your logo size */
}

nav {
  display: flex;
  align-items: center;
}

nav a {
  color: white;
  text-decoration: none;
  margin-left: 20px;
  font-family: 'Montserrat', sans-serif;
}

nav a:hover {
  text-decoration: underline;
}

/* Dropdown Button */
.dropbtn {
  background-color: transparent;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
}

/* Dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding-top: 12px;
  padding-bottom: 12px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #f1f1f1}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #a1a1a1;
}

.hamburger {
  display: none;
  /* Style your hamburger icon here */
}

@media screen and (max-width: 768px) {
  .header .logo img {
    width: 200px; /* Adjust as per your logo size */
  }
  .hamburger {
    display: block;
    background: #f2f2f2; /* Example background color */
    border-radius: 50%; /* Makes it circular */
    width: 30px; /* Adjust size as needed */
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none; /* Removes border */
    cursor: pointer;
  }
  .header-title{
    font-size: 3em;
  }
  
  /* Additional styles to ensure the icon is properly centered and visible */
  .hamburger:after {
    content: '';
    display: block;
    position: relative;
  }

  .navigation-menu {
    display: none;
    position: fixed;
    right: 0;
    top: 0;
    width: 200px; /* Adjust width as needed */
    height: 100vh;
    background-color: black;
    flex-direction: column;
    align-items: start;
    padding: 20px;
    z-index: 1000;
  }

  .navigation-menu.expanded {
    display: flex;
  }

  /* Style for all navigation links including dropdown */
  .navigation-menu a, .dropbtn {
    color: white;
    text-decoration: none;
    margin: 10px 0; /* Consistent margin */
    font-family: 'Montserrat', sans-serif;
    width: 100%; /* Full width */
    text-align: left; /* Left align */
    background: none; /* No background for consistency */
    border: none; /* No border for consistency */
    padding: 8px 0; /* Consistent padding */
    font-size: 16px; /* Consistent font size */
    cursor: pointer;
  }

  .dropdown.show .dropdown-content {
    display: block;
  }

  .dropdown .dropdown-content {
    position: static;
    padding-left: 20px; /* Indent dropdown items */
  }

  .dropdown-content a {
    color: black; /* Style for dropdown links */
  }

}