@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.text-area {
    max-width: 600px; /* Adjust the width as needed */
    margin: 0 auto; /* Center the text area */
    padding: 20px;
    text-align: left; /* Align text to the right */
    font-family: 'Montserrat', sans-serif; /* Use the same font for consistency */
    color: rgb(70, 70, 70); /* Adjust text color as needed */
  }
  
  .text-area p {
    margin-bottom: 10px; /* Space between paragraphs */
  }
.contact-form-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    color: rgb(70, 70, 70);
  }

  .name-fields {
    display: flex;
    justify-content: flex-start; /* Adjust to start to avoid stretching the input fields */
  }
  
  .name-fields input {
    width: calc(50% - 10px); /* Adjust width to account for margin */
    margin-bottom: 15px; /* Margin for vertical spacing */
  }
  
  .name-fields input:first-child {
    margin-right: 10px; /* Space between the first name and last name fields */
  }
  
  .contact-form input, .contact-form textarea {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: 'Montserrat', sans-serif;
  }
  
  .submit-button {
    padding: 10px;
    background-color: rgb(49, 49, 49);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .submit-button-submitted{
    padding: 10px;
    background-color: rgb(134, 134, 134);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: rgb(134, 134, 134);
  }
  .submission-status {
    color: #28a745; /* Success color, change as needed */
    margin-top: 20px;
    text-align: center;
  }
  
  /* You might want a different color for error messages */
  .submission-status.error {
    color: #dc3545; /* Error color, change as needed */
  }